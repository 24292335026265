import React from "react";
import styled from "styled-components";
import { breakpoints, colors, imgix } from "../styles/variables";
import { Background } from "react-imgix";

const Image = styled(Background)`
  width: 100%;
  height: calc(50vw - 7.5px);
  background: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  position: relative;

  > h4 {
    padding: 4px 15px;
    font: 20px / 24px "RC Bold";
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;

    &.orange {
      color: ${colors.darkerOrange};
    }

    &.schwarz {
      color: #000;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: calc((1025px - 90px) / 4);

    > h4 {
      padding: 15px 15px;
      word-wrap: break-word;
      font: 28px / 34px "RC Bold";
      letter-spacing: 1.4px;
    }
  }
`;

interface GridImageProps {
  textfarbe: string;
  text: string;
  image: string;
}

const GridImage: React.FC<GridImageProps> = ({ textFarbe, text, image }) => (
  <>
    {image ? (
      <Image src={image} imgixParams={imgix.smaller}>
        <h4 className={textFarbe}>{text}</h4>
      </Image>
    ) : (
      ""
    )}
  </>
);

export default GridImage;
