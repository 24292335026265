import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/variables";

const Grid = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacers.grid.mobile}px;
  margin-bottom: 55px;

  > div {
    &:nth-of-type(1),
    &:nth-of-type(4),
    &:nth-of-type(9),
    &:nth-of-type(10) {
      grid-column: span 2;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: 1025px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: ${spacers.grid.desktop}px;
    margin-bottom: 80px;

    > div {
      &:nth-of-type(4),
      &:nth-of-type(9),
      &:nth-of-type(10) {
        grid-column: span 1;
      }

      &:nth-of-type(1) {
        grid-row: span 2;
        height: calc((1025px - 30px) / 2);
      }

      &:nth-of-type(2),
      &:nth-of-type(8) {
        grid-column: span 2;
      }

      &:nth-of-type(6) {
        grid-column: span 3;
        grid-row: span 2;
        height: calc((1025px - 30px) / 2);
      }
    }
  }
`;

const ImageGrid: React.FC = ({ children }) => <Grid>{children}</Grid>;

export default ImageGrid;
