import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import DefaultLayout from "../layouts/default";
import Landing from "../components/Landing";
import Text from "../components/Text";
import TextWrapper from "../components/Layouting/TextWrapper";
import DownloadButton from "../components/DownloadButton";
import Willkommen from "../components/Willkommen";
import IconGrid from "../components/IconGrid";
import ImageGrid from "../components/ImageGrid";
import GridImage from "../components/GridImage";

interface IndexPageProps {
  cms: {
    startseite: {
      title: string;
      beschreibung: string;
      bild: [{ url: string }];
      text: string;
      bilderGridMatrix: [
        {
          textFarbe: string;
          bildText: string;
          bild: [
            {
              url: string;
            }
          ];
        }
      ];
    };
  };
}

const IndexPage: React.FC<IndexPageProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        startseite: entry(type: "startseite") {
          title
          ... on CMS_main_startseite_Entry {
            beschreibung
            bild {
              url
            }
            text
            titelFarbe
            bilderGridMatrix {
              ... on CMS_bilderGridMatrix_bild_BlockType {
                textFarbe
                bildText
                bild {
                  url
                }
              }
            }
          }
        }
        data: entry(type: "data") {
          ... on CMS_data_data_Entry {
            menuePdfMatrix {
              ... on CMS_menuePdfMatrix_wochenmenue_BlockType {
                menue {
                  url
                }
                gueltigBis
              }
            }
          }
        }
      }
    }
  `);

  const [aktuellesMenu, setAktuellesMenu] = useState(false);

  useEffect(() => {
    const now = new Date(Date.now());

    const aktuell = data.cms.data.menuePdfMatrix.filter((item) => {
      const itemDate = new Date(item.gueltigBis);
      if (itemDate >= now) {
        return item;
      }
    });

    setAktuellesMenu(aktuell[0]);
  }, []);

  return (
    <DefaultLayout beschreibung={data.cms.startseite.beschreibung}>
      <Landing
        image={data.cms.startseite.bild[0].url}
        downloadUrl={aktuellesMenu ? aktuellesMenu.menue[0].url : false}
        color={data.cms.startseite.titelFarbe}
      />
      <Willkommen mobile />
      <TextWrapper>
        <Text text={data.cms.startseite.text} center />
      </TextWrapper>
      {aktuellesMenu ? (
        <DownloadButton
          center
          mobileOnly
          downloadUrl={aktuellesMenu.menue[0].url}
        />
      ) : (
        ""
      )}
      <ImageGrid>
        {data.cms.startseite.bilderGridMatrix.map((entry) => {
          if (entry.bild[0] !== undefined && entry.bild[0].url !== undefined) {
            return (
              <GridImage
                image={entry.bild[0].url}
                text={entry.bildText}
                textFarbe={entry.textFarbe}
              />
            );
          } else {
            return null;
          }
        })}
      </ImageGrid>
      <IconGrid />
    </DefaultLayout>
  );
};

export default IndexPage;
