import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding: 30px;
  max-width: 530px;
  margin: 0 auto 55px;

  > img {
    display: block;
    width: calc((100vw - 90px) / 2);
    height: calc((100vw - 90px) / 2);

    max-width: 220px;
    max-height: 220px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0px;
    max-width: 1025px;
    margin-bottom: 120px;
  }
`;

const IconGrid = () => (
  <GridDiv>
    <img src="/assets/gestaltungselemente/nat-zutaten.svg" />
    <img src="/assets/gestaltungselemente/brot.svg" />
    <img src="/assets/gestaltungselemente/fleisch.svg" />
    <img src="/assets/gestaltungselemente/bauernhof.svg" />
  </GridDiv>
);

export default IconGrid;
